:root {
  --white: #fff;
  --black: #000;
  --bg-primary: #cc8b35;
  --bg-secondary: #fffef3;
  --text-primary: #141414;
  --text-secondary: #aaaaaa;
  --text-grey: #5e5e5e;
  --text-yellow: #eba300;
  --text-green: #7e846c;
  --text-lime: #0cbf41;
  --text-red: #f16c57;
  --text-black: #232a24;
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Additional styles */
@import "./custom/button.css";
@import "./custom/sizing.css";
@import "./custom/text.css";
@import "./custom/sidebar.css";
