input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.add-jewellery-button {
  border-color: var(--text-yellow);
  border-width: 1px;
  width: 220px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.edit-button {
  color: var(--text-yellow);
  background-color: white;
  box-shadow: 0px 8px 31px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 8px;
  position: absolute;
  margin-top: -35px;
  margin-left: 20px;
}
.delete-button {
  align-self: baseline;
  color: red;
  padding-right: 5px;
  margin-left: 10px;
}
.pending-tag {
  background-color: orange;
  color: #fff;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
}
.verified-tag {
  background-color: green;
  color: #fff;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
}
.signup-button {
  background: #eba300;
  color: white !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px;
  border-radius: 6px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-button {
  border: 1px solid #eba300;
  color: #eba300 !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px;
  border-radius: 6px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-portfolio-button {
  height: 35px;
  border: 1px solid var(--text-yellow);
  color: var(--text-yellow);
  padding: 0px 15px;
  border-radius: 6px;
  font-size: 15px;
  margin-top: 10px;
}
.scroll-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: white;
  box-shadow: 0px 8px 31px rgba(0, 0, 0, 0.17);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
}
.back-button {
  position: fixed;
  left: 3%;
  top: 11%;
  background-color: white;
  box-shadow: 0px 8px 31px rgba(0, 0, 0, 0.17);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.load-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-secondary);
  border-radius: 20px;
  width: max-content;
  padding: 7px 20px;
  margin: 30px auto;
  cursor: pointer;
}
.bid-button {
  border-color: var(--text-yellow);
  border-width: 1px;
  height: 60px;
  width: 45%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-yellow);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
}
.view-button {
  border-color: var(--text-yellow);
  border-width: 1px;
  height: 40px;
  width: 35%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-yellow);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
}
.buy-button {
  background-color: var(--text-yellow);
  border-width: 1px;
  height: 60px;
  width: 45%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
}
.disabled-buy-button {
  background-color: var(--text-secondary);
  border-width: 1px;
  height: 60px;
  width: 45%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
}
.add-button {
  background-color: var(--text-yellow);
  border-width: 1px;
  height: 60px;
  width: 45%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
}
.cancel-button {
  border-color: var(--text-yellow);
  border-width: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-yellow);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
  padding: 1% 4% 1% 4%;
}
.city-button {
  padding: 2% 3% 2% 3%;
  background-color: var(--text-secondary);
  margin-right: 10px;
  border-radius: 6px;
  color: white;
}
.selected-city-button {
  padding: 2% 3% 2% 3%;
  background-color: var(--text-yellow);
  margin-right: 10px;
  border-radius: 6px;
  color: white;
}
.disabled-upload-button {
  background-color: var(--text-secondary);
  border-width: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  padding: 1% 4% 1% 4%;
}

.upload-button {
  background-color: var(--text-yellow);
  border-width: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  padding: 1% 4% 1% 4%;
}
.shop-button {
  background-color: var(--text-yellow);
  border-width: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  padding: 1% 4% 1% 4%;
  height: 50px;
  width: 220px;
}
.next-auth-button {
  background-color: var(--text-yellow);
  border-width: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  padding: 3% 0% 3% 0%;
  width: 100%;
}
.next-auth-button-disabled {
  background-color: var(--text-secondary);
  border-width: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  padding: 3% 0% 3% 0%;
  width: 100%;
}
.add-doc {
  border-width: 1px;
  border-color: var(--text-yellow);
  border-radius: 100px;
  padding: 6px;
  margin-top: 10px;
  height: 30px;
  width: 30px;
}
.doc-icon {
  margin-top: 10px;
  height: 30px;
  width: 30px;
}
.radio-icon {
  height: 20px;
  width: 20px;
  border: 4px solid var(--text-secondary);
  border-radius: 100%;
}
.selected-radio-icon {
  height: 20px;
  width: 20px;
  border: 7px solid var(--text-yellow);
  border-radius: 100%;
}
.next-button {
  background-color: var(--text-yellow);
  height: 45px;
  width: 210px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-button {
  background-color: var(--text-red);
  height: 45px;
  width: 210px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled-next-button {
  background-color: var(--text-secondary);
  height: 45px;
  width: 210px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-container {
  height: 32px;
  width: 32px;
  background-color: var(--bg-secondary);
  border-radius: 32px;
  box-shadow: 0px 8px 31px rgba(0, 0, 0, 0.2);
  margin-right: 60px;
}
.select-option {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--text-secondary);
  border: 2px solid var(--text-secondary);
  border-radius: 6px;
  width: max-content;
  padding: 5px 10px 5px 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
  margin-left: 0px !important;
}
.checkbox {
  border: 2px solid var(--text-secondary);
  border-radius: 3px;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  cursor: pointer;
}
.selected-checkbox {
  background-color: var(--text-yellow);
  border-radius: 3px;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.select-option:hover {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--text-yellow);
  border: 2px solid var(--text-yellow);
  border-radius: 6px;
  width: max-content;
  padding: 5px 10px 5px 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
}
.selected-select-option {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--text-yellow);
  border: 2px solid var(--text-yellow);
  border-radius: 6px;
  width: max-content;
  padding: 5px 10px 5px 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
  margin-left: 0px !important;
}
.is_approved_0 {
  margin-top: 7px;
  flex-direction: row;
  border-width: 1px;
  padding: 7px 10px;
  border-color: orange;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: max-content;
}
.is_approved_1 {
  margin-top: 7px;
  flex-direction: row;
  border-width: 1px;
  padding: 7px 10px;
  border-color: #0cbf41;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: max-content;
}
.is_approved_2 {
  margin-top: 7px;
  flex-direction: row;
  border-width: 1px;
  padding: 7px 10px;
  border-color: red;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: max-content;
}
.is_approved_text_0 {
  color: orange;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  text-align: center;
}
.is_approved_text_1 {
  color: #0cbf41;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  text-align: center;
}
.is_approved_text_2 {
  color: red;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .back-button {
    left: 2%;
    top: 12%;
  }
}
@media screen and (max-width: 800px) {
  .add-jewellery-button {
    width: 35%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .back-button {
    left: 6%;
  }
}
@media screen and (max-width: 800px) {
  .back-button {
    left: 5%;
  }
  .back-container {
    margin-right: 30px;
  }
  .bid-button,
  .buy-button {
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    flex: 1;
    margin: 0px 3px;
  }
  .upload-button,
  .cancel-button,
  .disabled-upload-button {
    height: 50px;
    width: 45%;
    font-size: 10px;
  }
  .shop-button {
    height: 30px;
    font-size: 10px;
    width: 35%;
  }
  .city-button,
  .selected-city-button {
    padding: 3% 4% 3% 4%;
    font-size: 10px;
  }
  .next-button {
    height: 30px;
    width: 120px;
  }
  .disabled-next-button {
    height: 30px;
    width: 120px;
  }
  .select-option,
  .selected-select-option {
    font-size: 12px;
    padding: 3px 8px 3px 8px;
    margin-right: 10px;
  }
  is_approved_0,
  .is_approved_1,
  .is_approved_2 {
    padding: 5px;
    margin: 3px;
  }
  .is_approved_text_0,
  .is_approved_text_1,
  .is_approved_text_2 {
    font-size: 14px;
  }
}
