.card-title {
  color: white;
  font-size: 20px;
  width: 90%;
  font-family: Rubik;
  margin-top: 16px;
  text-align: left;
  font-weight: 600;
  /* line-height: 19px; */
}
.footer-heading {
  color: #5e5e5e;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}
.share-text {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-primary);
  margin-top: 10px;
}
.recommended-tag {
  position: absolute;
  color: white;
  background-color: var(--text-yellow);
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 10px;
  margin-top: -30px;
  margin-left: 170px;
}
.light-grey-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7c7c7c;
}
.footer-text {
  margin-top: 16px;
  color: #989898;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.carousel-main-text {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  color: #222222;
}
.carousel-secondary-text {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #222222;
  opacity: 0.6;
}
.text-yellow {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--text-yellow) !important;
  margin-right: 30px;
}
.text-c-black {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #4a4a4a;
  margin-right: 30px;
}
.asset-amount {
  color: white;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  line-height: 21px;
  /* flex: 1; */
}
.pay-amount-text {
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-secondary);
  align-self: center;
}
.loan-main-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--text-primary);
}
.bidding-orange-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #cc8b35;
  margin-top: 10px;
}
.loan-orange-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #f9b11d;
}
.loan-sub-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--text-secondary);
}
.add-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #5f6657;
}
.progress-text {
  font-size: 12px;
  color: var(--text-primary);
  font-weight: 400;
  font-family: Rubik;
  text-align: center;
  width: 20%;
}
.label-class {
  font-size: 12px;
  color: var(--text-primary);
  font-weight: 400;
  font-family: Rubik;
}
.select-content {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--text-secondary);
  margin-top: 10px;
  width: 80%;
}
.select-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-yellow);
}
.asset-weight {
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.view-all {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-yellow);
  font-family: Rubik;
  cursor: default;
}
.ocassion-title {
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  width: 100%;
  font-family: Rubik;
}
.content {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-secondary);
  width: 80%;
  font-family: Rubik;
}
.title {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-primary);
  font-family: Rubik;
}
.category-text {
  color: #232a24;
  font-size: 15px;
  font-weight: 400;
  margin-top: 100px;
  width: 130px;
  text-align: center;
  font-family: Rubik;
}
.add-jewellery-text {
  color: var(--bg-primary);
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
  font-family: Rubik;
}
.price {
  color: #232a24;
  font-size: 28px;
  font-weight: 500;
  font-family: Rubik;
}
.net-worth-text {
  color: #989898;
  font-size: 18px;
  font-weight: 400;
  font-family: Rubik;
}
.paise-text {
  color: var(--text-green);
  font-size: 20px;
  font-weight: 500;
  margin-top: 7px;
  margin-left: 5px;
  font-family: Rubik;
}
.portfolio-small-card {
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
  background-color: #fffef3;
  height: 33px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-time-text {
  font-family: Rubik;
  color: var(--text-primary);
  font-size: 10px;
  font-weight: 400;
  margin: 2% 0% 2% 0%;
  background-color: rgba(220, 220, 220, 0.3);
  padding: 5px;
  margin: 5px;
  border-radius: 4px;
}
.selected-time-text {
  font-family: Rubik;
  color: white;
  font-size: 10px;
  font-weight: 400;
  margin: 2% 0% 2% 0%;
  background-color: var(--text-yellow);
  padding: 5px;
  margin: 5px;
  border-radius: 4px;
}
.select-day-text {
  font-family: Rubik;
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 800;
  margin: 2% 0% 2% 0%;
}
.select-month-text,
.select-week-text {
  font-family: Rubik;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
}
.selected-day-text {
  font-family: Rubik;
  color: white;
  font-size: 18px;
  font-weight: 800;
  margin: 2% 0% 2% 0%;
}
.selected-month-text,
.selected-week-text {
  font-family: Rubik;
  color: white;
  font-size: 14px;
  font-weight: 400;
}
.asset-name {
  color: #141414;
  font-size: 14px;
  font-weight: 500;
  font-family: Rubik;
  text-align: left;
}
.portfolio-red-text {
  color: var(--text-red);
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  text-align: left;
}
.green-dot {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #0cbf41;
}
.yellow-dot {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #eba300;
}
.green-text {
  color: #0cbf41;
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.red-dot {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: red;
}
.listing_name {
  color: var(--text-primary);
  font-size: 24px;
  font-weight: 500;
  font-family: Rubik;
}
.gross_weight {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.payment-name {
  color: var(--text-primary);
  font-size: 20px;
  font-weight: 500;
  font-family: Rubik;
}
.payment-price {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 400;
  font-family: Rubik;
}
.listing_title {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.listing_price {
  color: var(--text-lime);
  font-size: 18px;
  font-weight: 400;
  font-family: Rubik;
}
.listing_price_dark {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 400;
  font-family: Rubik;
}
.portfolio-secondary-text {
  color: var(--text-secondary);
  font-size: 10px;
  font-weight: 400;
  font-family: Rubik;
  text-align: left;
}
.no-asset {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 25px;
  color: #5e5e5e;
  line-height: 40px;
  font-family: Rubik;
}
.no-asset-content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: var(--text-secondary);
  line-height: 30px;
  font-family: Rubik;
}
.title_without-card {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-primary);
  margin-left: 5%;
  margin-bottom: 1%;
  font-family: Rubik;
}
.pink-text {
  color: var(--text-red);
  font-size: 22px;
  font-weight: 400;
  font-family: Rubik;
}
.black-text {
  color: var(--text-primary);
  font-size: 22px;
  font-weight: 500;
  margin-left: 5px;
  font-family: Rubik;
}
.product-title {
  color: var(--text-primary);
  font-size: 26px;
  font-weight: 500;
  font-family: Rubik;
}
.product-karat {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #828282;
}
.product-price {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--bg-primary);
}
.input-label {
  color: var(--text-primary);
  margin-top: 5%;
  margin-bottom: 1%;
  margin-left: 5%;
}
.input-label-asset {
  color: var(--text-yellow);
  margin-bottom: 2%;
}
.product-weight {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--text-yellow);
  border-color: var(--text-yellow);
  border-width: 1px;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
}
.product-sale-value {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0cbf41;
  border-color: #0cbf41;
  border-width: 1px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: max-content;
}
.breakdown-heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
}
.breakdown-component {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5f6657;
}
.breakdown-border {
  border: 0.5px solid #e3e3e1;
  border-radius: 4px;
}
.bid-amount {
  color: var(--text-yellow);
  flex: 1;
  text-align: right;
}
.bid-name {
  color: var(--text-primary);
  flex: 2;
}
.detail-name {
  color: #080e33;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.blue-color {
  color: rgb(32, 129, 226) !important;
}
.detail-value {
  color: var(--text-secondary);
  flex: 2;
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.address_title {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 600;
  font-family: Rubik;
}
.address_name {
  color: var(--text-secondary);
  font-size: 15px;
  font-weight: 400;
  font-family: Rubik;
}
.add-address {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: Rubik;
}
.next-text {
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
  margin-right: 10px;
}
.sub-card-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--text-black);
}
.sub-card-red-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-red);
}
.info-red-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--text-red);
  margin-top: 1%;
  margin-left: 5%;
}
.profile-icon {
  font-size: 24px;
  height: 24px;
}
.profile-text {
  font-size: 20px;
  color: var(--text-primary);
  font-weight: 600;
  font-family: Rubik;
}
.profile-sub-text {
  font-size: 18px;
  color: var(--text-secondary);
  font-weight: 400;
  font-family: Rubik;
}
/* media queries */
@media screen and (max-width: 800px) {
  .payment-name {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    font-family: Rubik;
  }
  .carousel-main-text {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 38px;
    text-align: center;

    color: #222222;
  }
  .carousel-secondary-text {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    opacity: 0.6;
  }
  .footer-heading {
    font-size: 18px;
  }
  .footer-text {
    font-size: 12px;
  }
  .category-text {
    font-size: 9px;
    margin-top: 60px;
  }
  .title {
    font-size: 18px;
  }
  .info-red-text {
    font-size: 10px;
  }
  .portfolio-small-card {
    font-size: 8px;
    height: 24px;
  }
  .asset-name {
    font-size: 14px;
  }
  .portfolio-red-text {
    font-size: 12px;
  }
  .portfolio-secondary-text {
    font-size: 9px;
  }
  .loan-main-text,
  .loan-orange-text {
    font-size: 10px;
  }
  .loan-sub-text {
    font-size: 8px;
  }
}
@media screen and (max-width: 600px) {
  .address_title {
    font-size: 14px;
  }
  .bidding-orange-text {
    font-size: 12px;
  }
  .view-all {
    font-size: 14px;
  }
  .net-worth-text {
    font-size: 12px;
    width: 60%;
  }
  .add-jewellery-text {
    font-size: 10px;
  }
  .pay-amount-text {
    font-size: 10px;
  }
  .progress-text {
    font-size: 10px;
  }
  .no-asset {
    font-size: 22px;
  }
  .select-title {
    font-size: 14px;
  }
  .next-text {
    font-size: 14px;
    font-weight: 400;
  }
  .sub-card-title {
    font-weight: 400;
    font-size: 14px;
  }
  .sub-card-red-text {
    font-weight: 400;
    font-size: 12px;
  }
  .add-address {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: Rubik;
  }
  .select-content {
    font-size: 12px;
    line-height: 21px;
    margin-top: 5px;
    width: 90%;
  }
  .price {
    font-size: 14px;
  }
  .paise-text {
    font-size: 12px;
    margin-top: 1px;
    margin-left: 1px;
  }
  .content {
    width: 100%;
  }
  .card-title {
    font-size: 14px;
    margin-top: 14px;
  }
  .asset-weight {
    font-size: 10px;
    font-weight: 400;
  }
  .asset-weight {
    font-size: 10px;
  }
  .asset-amount {
    font-size: 12px;
  }
  .product-title {
    font-size: 22px;
  }
  .breakdown-heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #444444;
  }
  .detail-value {
    flex: 1.3;
    font-size: 10px;
  }
  .profile-text {
    font-size: 18px;
    color: var(--text-primary);
    font-weight: 600;
    font-family: Rubik;
  }
  .profile-sub-text {
    font-size: 14px;
    color: var(--text-secondary);
    font-weight: 400;
    font-family: Rubik;
  }
  .profile-icon {
    font-size: 20px;
    height: 20px;
  }
  .listing_name {
    font-size: 18px;
  }
  .listing_title,
  .listing_price,
  .listing_price_dark {
    font-size: 12px;
  }
}
::placeholder {
  color: var(--text-secondary);
  font-size: 18px;
  font-weight: 400;
}
.input-text {
  color: black;
  font-size: 18px;
  font-weight: 400;
}
@media screen and (max-width: 400px) {
  .net-worth-text {
    font-size: 9px;
  }
}
