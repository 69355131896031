/* ---------------------------------- tool bar starts---------------------------- */
.toolbar {
  /* position: fixed;
    top: 0;
    left: 0; */
  width: 100%;
  background-color: #cc8b35;
  height: 56px;
}
.cross-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
.login-header-container {
  width: 100%;
  z-index: 1000;
  margin-top: 0px;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
}
.header-container {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0px;
  margin-top: 0px;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
}
.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_logo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

/* .toolbar_navigation-items a:hover,
  .toolbar_navigation-items a:active {
    color: rgb(223, 75, 30);
  } */

@media screen and (max-width: 760px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media screen and (max-width: 759px) {
  .toolbar {
    height: 2px;
  }
  .toggle-icon {
    display: none;
  }
}
@media screen and (min-width: 759px) {
  .toggle-btn {
    /* display: none; */
  }

  .toolbar_logo {
    margin-left: 0;
  }
}
/* --------------------------------- toolbar ends -------------------------- */

/* ---------------------------------- menuDrawer starts---------------------- */
.toggle-button {
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button-line {
  width: 30px;
  height: 2px;
  background: var(--text-yellow);
}
/* ----------------------------------- side menu ends -----------------  */

/* -------------------------------  side drawer starts----------------------- */
.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;
  max-width: 400px;
  z-index: 1002;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overflow-y: scroll;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  text-decoration: none;
  color: #521751;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #cc8b35;
}

@media screen and (min-width: 759px) {
  .side-drawer {
    /* display: none; */
  }
  .header-container {
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0px;
    margin-top: 0px;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
  }
}
/* ------------------------------------- side drawer ------------------------  */

/* --------------------------------- backdrop starts--------------------- */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.3); */
  background: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

/* -------------------------------- backdrop ends -------------------------- */
