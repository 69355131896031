.view-all-card {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 1%;
}
.yellow {
  color: var(--text-yellow);
}
.react-multiple-carousel__arrow {
  z-index: 200 !important;
}
.edit-icon {
  width: 30px;
}
.deal-box {
  width: 20px;
  height: 20px;
  background-color: #ec6b56;
  margin-right: 10px;
  border-radius: 4px;
}
.paid-box {
  width: 20px;
  height: 20px;
  background-color: green;
  margin-right: 10px;
  border-radius: 4px;
}
.due-box {
  width: 20px;
  height: 20px;
  background-color: #eba300;

  margin-right: 10px;
  border-radius: 4px;
}
.min-600 {
  min-width: 500px;
}
.flex-4 {
  flex: 4;
}
.only-desktop {
  display: block;
  visibility: visible;
}
.icon-header {
  height: 12px;
  width: 12px;
}
.icon-social {
  height: 16px;
  width: px;
}
.box-shadow {
  box-shadow: 11px 13px 74px rgba(0, 0, 0, 0.05);
}
.market-container {
  background-color: white;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 5px;
  width: 81%;
  margin-left: auto;
  box-shadow: 11px 13px 74px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0px;
  height: 90vh;
  overflow-y: scroll;
}
.number-input-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  height: 74px;
  background-color: white;
  width: 100%;
  max-width: 556px;
  z-index: 1;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  overflow: hidden;
}
.country-code {
  height: 100%;
  border-right: 1px solid #aaaaaa;
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #141414;
  font-size: 18px;
  font-weight: 500;
}
.footer-button {
  margin-right: 20px;
  height: 50%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: white;
  font-size: 12px;
  font-weight: 900;
  line-height: 5;
  background-color: #eba300;
}
.multi-range-slider .thumb .caption * {
  background-color: #eba300 !important;
  box-shadow: none;
}
.multi-range-slider .thumb::before {
  border-width: 1px !important;
  box-shadow: none;
}
.multi-range-slider .bar-inner {
  border-width: 0px !important;
  box-shadow: none !important;
}
.multi-range-slider .bar-right,
.multi-range-slider .bar-left {
  box-shadow: none;
  background-color: rgba(220, 220, 220, 1);
}
.mobile-show-filter,
.mobile-hide-filter {
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  width: 60%;
  box-shadow: 11px 13px 74px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 30px;
  height: 100vh;
  transition: 0.5s;
  position: fixed;
  visibility: hidden;
  z-index: 1001;
  overflow-y: scroll;
  padding-bottom: 100px;
}
.desktop-filter-container {
  position: fixed;
  background-color: white;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 5px;
  padding: 20px;
  height: max-content;
  width: 15%;
  height: 90vh;
  box-shadow: 11px 13px 74px rgba(0, 0, 0, 0.1);
  overflow: scroll;
}
.select-city {
  outline: none;
  font-size: 18px;
  width: 100%;
  margin-right: 40px;
  border-bottom: 1px solid var(--text-yellow);
  padding: 8px;
  background-color: white;
  border-radius: 0px;
}
input::placeholder {
  font-size: 16px;
}
.auth-input {
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 11px 13px 74px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 3px 10px 3px 10px;
  outline: none;
  border: 1px solid #aaaaaa;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yellow-line {
  width: 3px;
  height: 50px;
  background-color: var(--text-yellow);
}
.double-card {
  width: 48%;
}
.scheme-container {
  width: 500px;
  margin: auto;
}
.grey-line {
  height: 50px;
  width: 3px;
  background-color: var(--text-secondary);
}
.border-bottom {
  border-bottom-color: #989898;
  border-bottom-width: 1px;
  padding-bottom: 20px;
}
.loan-image {
  width: 30%;
  height: auto;
  align-self: center;
  margin: auto;
}
.date-container {
  background-color: rgba(220, 220, 220, 0.3);
  margin: 3% 2% 3% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2%;
  border-radius: 6px;
}
.active-loan-card {
  background-image: linear-gradient(#fff5f3, #fef6ea, #fff8d3);
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-bottom: 20px !important;
}
.selected-date-container {
  background-color: var(--text-yellow);
  margin: 3% 2% 3% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2%;
  border-radius: 6px;
}
.yellow-dotted-line {
  height: 30px;
  width: 0px;
  border-left: 3px dotted var(--bg-primary);
}
.dotted-line {
  height: 30px;
  width: 0px;
  border-left: 3px dotted var(--text-secondary);
}
.yellow-circle {
  width: 25px;
  height: 25px;
  background-color: var(--text-yellow);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.yellow-border {
  width: 25px;
  height: 25px;
  border: 3px solid var(--text-yellow);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-yellow);
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.grey-border {
  width: 25px;
  height: 25px;
  border: 3px solid var(--text-secondary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  font-family: Rubik;
}
.gradient-image {
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  width: 235px;
  height: 190px;
  line-height: 15;
}

.portfolio-image {
  height: 160px;
  width: 230px;
  object-fit: cover;
  /* border-radius: 6px; */
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.orders-image {
  height: 140px;
  width: 140px;
  object-fit: cover;
  /* border-radius: 6px; */
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.payment-image {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 70px;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.1);
  align-self: center;
}
.select-step {
  width: 120px;
  height: 45px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #989898;
  background-color: #e2edec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.border-bottom-grey {
  height: 3px;
  background: #dcdcdc;
  opacity: 0.4;
  align-self: center;
}
.jewellery-card {
  width: 18%;
  min-width: 250px;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.07);
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}
.jewellery-card-new {
  width: 22%;
  height: 230px;
  min-width: 250px;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.07);
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}
.jewellery-card-portfolio {
  width: 250px;
  min-width: 250px;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.07);
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}
.jewellery-image-container {
  height: 200px;
  width: 250px;
  overflow: hidden;
}

.jewellery-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: 0.2s ease-out;
}
.jewellery-card:hover .jewellery-image {
  scale: 1.2;
}
.jewellery-card:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
}

/* new image card */
.jewellery-image-container-new {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.gradient-color {
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8)
  );
  z-index: 3;
  height: 100%;
  width: 100%;
  margin-top: -200px;
  position: sticky;
}
.card-info {
  margin-top: -100px;
  z-index: 100;
}
.jewellery-image-new {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: 0.2s ease-out;
  z-index: 2;
}
.jewellery-card-new:hover .jewellery-image-new {
  scale: 1.2;
}
.jewellery-card-new:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
}

.jewellery-card-portfolio:hover .jewellery-image-new {
  scale: 1.2;
}
.jewellery-card-portfolio:hover {
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
}
.weight-card {
  background-color: var(--bg-secondary);
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.ocassion-card {
  width: 280px;
  height: 250px;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.03);
  background-color: white;
}
.ocassion-image {
  width: 90%;
  height: auto;
  margin-top: 5%;
}
.loan-card {
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 12px;
}
.yellow-top-card {
  background-color: var(--text-yellow);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: white;
  padding: 1% 2% 1% 2%;
  font-size: 28px;
  font-family: Rubik;
  font-weight: 800;
}
.top-small-text {
  font-size: 12px;
}
.card-main-text {
  color: var(--text-primary);
  font-family: Rubik;
  font-weight: 600;
  font-size: 20px;
}
.card-sub-text {
  color: var(--text-secondary);
  font-family: Rubik;
  font-weight: 400;
  font-size: 12px;
}
.full-grey-line {
  border-width: 1px;
  border-color: #dcdcdc;
  width: 100%;
}
.wallet-image-container {
  background-color: var(--bg-secondary);
  border-radius: 500px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100px;
  height: 100px;
}
.wallet-icon {
  width: 80%;
  height: 80%;
}
.new-for-you-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5%;
  padding-right: 5%;
  padding-left: 5%;
}
.carousel-container {
  width: 95%;
}
.carousel-image-container {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}
.carousel-image {
  height: 80%;
  margin: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
.m-5per {
  margin: 5%;
}
.m-3per {
  margin: 3%;
}
.mrlb-5per {
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
}
.mrl-5per {
  margin-left: 5%;
  margin-right: 5%;
}
.p-5per {
  padding: 5%;
  padding-top: 2% !important;
}
.p-3per {
  padding: 3%;
}
.prl-3per {
  padding-right: 3%;
  padding-left: 3%;
}
.w-90per {
  width: 90%;
}
.pink-underline {
  border-color: var(--text-red);
  border-width: 0.5px;
}
.market-header {
  padding: 1% 3% 1% 3%;
}
.category-container {
  width: 130px;
  margin: 20px 20px 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.category-image {
  position: absolute;
  height: 80px;
  width: 80px;
  margin: auto;
  border-radius: 100%;
  object-fit: cover;
}
.category-container:hover {
  scale: 1.15;
}
.net-worth {
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 1%;
  padding-top: 1%;
}
.product-image {
  width: 100%;
  height: 45vh;
  border-radius: 12px;
}
.product-image-small {
  width: 70px;
  height: 70px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 8px 31px rgba(0, 0, 0, 0.05);
  margin-right: 20px;
  cursor: pointer;
}
.product-image-small-a {
  width: 70px;
  height: 70px;
  border-radius: 12px;
  background-color: white;
  border: 2px solid #eba300;
  box-shadow: 0px 8px 31px rgba(0, 0, 0, 0.05);
  margin-right: 20px;
  cursor: pointer;
}
.listing-image {
  border-radius: 12px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.product-container {
  width: 40%;
}
.vertical-line {
  height: 20px;
  width: 2px;
  background-color: var(--text-primary);
  border-radius: 3px;
}
.sticky-white-card {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.sticky-only-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  width: 100vw;
  padding: 3% 5%;
  margin: 0px;
  visibility: hidden;
  display: none;
  z-index: 10000 !important;
}
.sticky-white-button {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-yellow);
  font-size: 18px;
  font-weight: 500;
  font-family: Rubik;
  padding: 1% 4% 1% 4%;
}
.sub-white-card {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: auto;
}
.display-none {
  display: none;
}
.white-card {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: 10px;
}
.min-w-40 {
  width: 40vw;
  min-width: 250px;
}
.scheme-full-card {
  width: 100%;
  border: 2px solid rgba(220, 220, 220, 0.5);
  border-radius: 10px;
}
.selected-scheme-full-card {
  width: 100%;
  border: 2px solid var(--text-yellow);
  border-radius: 10px;
}
.scheme-sub-card {
  width: 100%;
  border: 2px solid rgba(220, 220, 220, 0.5);
  border-radius: 10px;
  margin-top: 30px;
}
.selected-scheme-sub-card {
  width: 100%;
  border: 2px solid var(--text-yellow);
  border-radius: 10px;
  margin-top: 30px;
}
.step-wrapper {
  padding: 0px;
  min-width: 300px;
  width: 40vw;
}
.add-white-card-2 {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}
.add-white-card {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}
/* .add-white-card:hover {
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.08);
  transform: scale(1.02);
} */
.yellow-card {
  background: var(--text-yellow);
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: 10px;
}
.white-card-2 {
  background: #ffffff;
  box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}
.heart-container {
  border-radius: 100px;
  width: 56px;
  height: 56px;
  background: rgba(235, 163, 0, 0.2);
  align-items: center;
  justify-content: center;
  display: flex;
}
.flex-2 {
  flex: 1.3;
}
.product-page {
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
}
.no-biddings {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.step-content-container {
  display: flex;
  justify-content: space-between;
}
.step-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2% 7% 2% 8%;
}
.custom-modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-modal {
  width: 50%;
}
.custom-modal-2 {
  width: fit-content;
  overflow-x: scroll;
}

.custom-input-asset:focus {
  border: 1px solid var(--text-yellow);
}
.custom-input-asset {
  width: 90%;
  padding: 1% 2% 1% 2%;
  border-radius: 6px;
  border: 1px solid var(--text-secondary);
  outline: none;
  min-width: 300px;
  min-height: 35px;
}
.add-image-container {
  border: 1px solid var(--text-secondary);
  border-radius: 6px;
  padding: 2% 5% 2% 5%;
}
.add-image-button {
  border: 1px solid var(--text-yellow);
  border-radius: 6px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-image-button-xl {
  border: 1px solid var(--text-yellow);
  border-radius: 6px;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.min-width-300 {
  min-width: 300px;
}
.custom-input {
  width: 90% !important;
  margin: auto;
  padding: 1% 2% 1% 2%;
  border-radius: 6px;
  border: 1px solid var(--text-yellow);
  outline: none;
  background-color: white !important;
}
.custom-input-short {
  width: 100% !important;
  padding: 1% 2% 1% 2%;
  border-radius: 6px;
  border: 1px solid var(--text-yellow);
  outline: none;
  background-color: white !important;
}
.custom-input-address:focus {
  border-bottom: 1px solid var(--text-yellow);
}
.custom-input-address {
  width: 90%;
  margin: auto;
  padding: 1% 2% 1% 2%;
  border-bottom: 1px solid var(--text-yellow);
  outline: none;
  margin-top: 30px;
}
@media screen and (max-width: 1200px) {
  .market-container {
    width: 75%;
  }
  .desktop-filter-container {
    width: 20%;
  }
  .double-card {
    width: 100%;
  }
  .min-w-40 {
    width: 100%;
    min-width: 250px;
  }
  .step-wrapper {
    padding: 0px;
    min-width: 300px;
    width: 100%;
    margin-top: 30px;
  }
  .product-page {
    margin: auto;
    padding: 5%;
  }
}
@media screen and (max-width: 700px) {
  .market-container {
    position: relative;
  }
  .payment-image {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 70px;
    box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.1);
  }
  .footer-button {
    width: 40px;
  }
  .number-input-container {
    margin-top: 40px;
    height: 60px;
    width: 100%;
    max-width: 556px;
    z-index: 1;
  }
  .country-code {
    width: 55px;
    font-size: 14px;
  }
  .category-container {
    width: 70px;
    position: relative;
    height: 100px;
  }
  .category-image {
    height: 50px;
    width: 50px;
  }
  .jewellery-image-container {
    height: 150px;
    width: 100%;
  }
  .jewellery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .sticky-only-mobile {
    display: block;
    visibility: visible;
  }
  .only-desktop {
    display: none !important;
    visibility: hidden;
  }
  .market-container {
    flex: 1;
    margin: 2%;
    padding: 2%;
    margin-top: 20px;
  }
  .desktop-filter-container {
    display: none;
    visibility: hidden;
  }
  .mobile-show-filter {
    visibility: visible;
    transform: translateX(0px);
  }
  .mobile-hide-filter {
    transform: translateX(-500px);
  }
  .min-600 {
    min-width: 300px;
  }
  .step-progress {
    padding: 2% 5% 2% 5%;
  }
  .portfolio-image {
    height: 120px;
    width: 150px;
    object-fit: cover;
    border-radius: 6px;
  }
  .orders-image {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 6px;
  }
  .custom-modal {
    width: 80%;
  }
  .hide-in-mobile {
    display: none;
  }
  .product-container {
    width: 100%;
  }
  .white-card {
    box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 100%;
    padding: 5%;
    margin: auto;
  }
  .yellow-card {
    padding: 3%;
    margin: auto;
  }
  .white-card-2 {
    width: 100%;
    padding: 5%;
  }
  .sub-white-card {
    padding: 5% 4% 5% 4%;
  }
}
@media screen and (max-width: 1400px) {
  .jewellery-card-new {
    width: 22%;
    min-width: 150px;
    height: 200px;
  }
}
@media screen and (max-width: 1200px) {
  .jewellery-card-new {
    width: 30%;
    min-width: 150px;
    height: 250px;
  }
}
@media screen and (max-width: 900px) {
  .jewellery-card-new {
    width: 45%;
    min-width: 150px;
    height: 220px;
  }
}
@media screen and (max-width: 700px) {
  .wallet-image-container {
    background-color: var(--bg-secondary);
    border-radius: 500px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 50px;
    height: 50px;
  }
  .loan-image {
    width: 50%;
    height: auto;
    align-self: center;
    margin: auto;
  }
  .step-progress {
    padding: 2% 4% 2% 4%;
  }
  .min-w-40 {
    justify-content: start;
  }
  .next {
    width: 15px;
    height: 10px;
  }
  .jewellery-card {
    width: 47%;
    min-width: 150px;
    height: auto;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  .jewellery-card-new {
    width: 47%;
    min-width: 150px;
    height: 180px;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  .gradient-color {
    margin-top: -150px;
  }
  .card-info {
    margin-top: -80px;
  }
  .jewellery-card-portfolio {
    width: 47%;
    min-width: 150px;
    height: auto;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.05);
    background-color: white;
  }

  .weight-card {
    background-color: var(--bg-secondary);
    height: 28px;
  }

  .m-5per {
    margin: 3%;
  }

  .mrlb-5per {
    margin-bottom: 3%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .p-5per {
    padding: 3% !important;
  }
  .p-3per {
    padding: 2%;
  }
  .prl-3per {
    padding-right: 2%;
    padding-left: 2%;
  }
  .ocassion-title {
    font-size: 12px;
  }
  .select-step {
    width: 70px;
    height: 25px;
    font-size: 10px;
  }
  .ocassion-card {
    width: 45%;
    height: auto;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.03);
    background-color: white;
  }
  .view-all-card {
    padding: 5%;
  }
  .net-worth {
    padding: 5%;
  }
  .sticky-white-card {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 100vw;
    padding: 5%;
    margin: 0px;
  }

  .sticky-white-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 100vw;
    padding: 5%;
    margin: 0px;
  }
  .custom-modal-container {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .custom-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 12px 54px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 100vw;
    padding: 2% 0% 2% 0%;
    margin: 0px;
  }
  .product-page {
    margin: auto;
    padding: 5% 3% 5% 3%;
    padding-bottom: 20% !important;
  }
  .custom-input,
  .custom-input-date,
  .custom-input-address {
    padding: 2% 3% 2% 3%;
  }
  .yellow-line,
  .grey-line {
    width: 2px;
  }
  .yellow-top-card {
    padding: 2% 3% 2% 3%;
    font-size: 22px;
  }
  .top-small-text {
    font-size: 10px;
  }
  .card-main-text {
    font-size: 14px;
  }
  .card-sub-text {
    font-size: 10px;
  }
}
@media screen and (max-width: 450px) {
  .scheme-container {
    width: 95%;
    margin: auto;
    margin-top: 20px;
  }
}
@media screen and (max-width: 400px) {
  .jewellery-image-container {
    height: 130px;
    width: 100%;
  }
  .jewellery-image {
    height: 100%;
    width: 100%;
  }
}
